import React, { useCallback, useEffect, useRef } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import { selectBlock, setFocusId } from '../../store/actions';
import Icon from '../ui/Icon';
import Button from '../ui/Button';
import Block from './Block';

import styles from './BlockList.module.scss';

function BlockList({ blocks, id, isDateList, section, owner }) {

  // const blocks = useSelector(state => state.firestore.ordered[`blocks-${id}`]) || [];
  const selection = useSelector(state => state.app.selection);
  const focusId = useSelector(state => state.app.focusId);
  const history = useHistory();
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const auth = useSelector(state => state.firebase.auth);
  const listId = id;
  const newTaskInput = useRef(null);
  const blockItems = useRef(null);

  // const sortedBlocks = blocks.slice();
  // sortedBlocks.sort((a, b) => {
  //   return (b.index || 0) - (a.index || 0);
  // });

  // useFirestoreConnect([
  //   {
  //     collection: 'blocks',
  //     where: ['list', '==', id],
  //     storeAs: `blocks-${id}`
  //   }
  // ]);

  let dragElement;
  let dragging = false;
  let sx;
  let sy;
  let inserter;
  let targetList;
  let targetPreviousSibling;
  let targetNextSibling;
  let originalList;

  function onMouseDown(e) {
    const block = e.target.closest('.block');
    originalList = block.closest('.blockList');

    if (!block) {
      return;
    }

    dragElement = block;
    sx = e.clientX;
    sy = e.clientY;

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  }

  function onMouseMove(e) {
    const dx = e.clientX - sx;
    const dy = e.clientY - sy;

    if (!dragging && (Math.abs(dx) > 3 || Math.abs(dy) > 3)) {

      // start dragging
      dragging = true;
      createInserter();
      dragElement.classList.add(styles.isHidden);
      setDropTarget(e);

    } else if (dragging) {

      // update drag
      setDropTarget(e);

    }
  }

  const onMouseUp = useCallback((e) => {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);

    if (!dragging) {
      return;
    }

    setDropTarget(e);

    if (!targetList) {
      return;
    }

    const dropDataString = targetList.dataset.dropdata;
    const dropData = JSON.parse(dropDataString);

    const blockId = dragElement.getAttribute('data-id');

    dragElement.classList.remove(styles.isHidden);
    inserter.remove();
    dragging = false;

    const selectedBlocks = originalList.querySelectorAll('.block.isSelected');

    // prevent dropping something into itself
    if (dropData.list === blockId) {
      return;
    }

    const previousSiblingIndex = targetPreviousSibling && parseFloat(targetPreviousSibling.getAttribute('data-index'));
    // const previousSiblingDate = this.targetPreviousSibling && this.targetPreviousSibling.getAttribute('data-date');
    const nextSiblingIndex = targetNextSibling && parseFloat(targetNextSibling.getAttribute('data-index'));
    // const nextSiblingDate = this.targetNextSibling && this.targetNextSibling.getAttribute('data-date');

    let targetIndex;
    let increment = 1;
    if (previousSiblingIndex === null && nextSiblingIndex === null) {

      // hunt for cards just in case we missed them somehow
      const blocks = targetList.querySelectorAll('.block');
      if (!blocks || !blocks.length) {
        targetIndex = 0;
      } else {
        targetIndex = parseFloat(blocks[blocks.length - 1].getAttribute('data-index'));
      }

    } else if (previousSiblingIndex !== null && nextSiblingIndex === null) {

      // append
      targetIndex = previousSiblingIndex - 1;
      increment = -1;

    } else if (nextSiblingIndex !== null && previousSiblingIndex === null) {

      // prepend
      targetIndex = nextSiblingIndex;

    } else if (previousSiblingIndex !== null && nextSiblingIndex !== null) {

      // use fractional index
      increment = ((nextSiblingIndex - previousSiblingIndex) / (selection.length + 1));
      targetIndex = previousSiblingIndex;

    }

    for (let i = 0; i < selectedBlocks.length; i += 1) {
      const id = selectedBlocks[i].getAttribute('data-id');
      targetIndex += increment;

      // check if the drop target is a date list
      const isTargetDateList = dropData && dropData.showDate;

      const toSet = {
        ...dropData,
        [isTargetDateList ? 'dateIndex' : 'index']: targetIndex,
      };

      firestore.collection('blocks').doc(id).update({
        ...toSet,
      })
      .then((doc)=> {
        // console.log('added', doc.id);
      })
      .catch((error) => {
        console.error(error);
      });
    }

  }, [selection, onMouseMove]);

  function createInserter() {
    inserter = document.createElement('div');
    inserter.setAttribute('class', styles.inserter);
    dragElement.parentElement.appendChild(inserter);
  }

  function setDropTarget(e) {
    const {
      clientY,
    } = e;

    const list = e.target.closest('.blockList');

    if (!list) {
      return;
    }

    const block = e.target.closest('.block');

    targetList = list;

    if (block) {

      // determine if we're at the top or bottom of the block
      const blockBounds = block.getBoundingClientRect();
      const isAtTop = clientY < (blockBounds.top + (blockBounds.height / 2));
      const anchor = isAtTop ? block : block.nextSibling;

      targetList.insertBefore(inserter, anchor);

    } else {

      // insert at the bottom of the list
      targetList.appendChild(inserter);

    }

    list.classList.add('isDropTarget');

    targetPreviousSibling = inserter.previousSibling;
    targetNextSibling = inserter.nextSibling;

  }

  function newBlock(text) {
    const block = {
      text,
      type: 'check',
      owner: auth.uid,
      created: new Date(),
      isChecked: false,
    };

    const newIndex = ((blocks && blocks[0] && blocks[0].index !== undefined) ? blocks[0].index : 1) + 1;

    if (isDateList) {
      block.list = null;
      block.showDate = id;
      block.dateIndex = newIndex;
    } else {
      block.list = id;
      block.index = newIndex;
    }

    firestore.collection('blocks').add(block)
    .then((doc)=> {
      // console.log('added', doc.id);
    })
    .catch((error) => {
      console.error(error);
    });
  }

  function onBlockDown(id, e) {
    const prevent = e.target.closest('[data-prevent-selection]');
    if (prevent || selection.includes(id)) {
      return;
    }

    dispatch( selectBlock(id, e.metaKey) );
  }

  function onBlockClick(id, e) {

  }

  function onBlockDoubleClick(id, e) {
    const prevent = e.target.closest('[data-prevent-selection]');
    if (prevent || owner === 'block') {
      return;
    }

    if (!e.metaKey) {
      history.push(`/${section}/${id}`);
    }
  }

  function onNewTaskKey(e) {
    const input = newTaskInput.current;

    switch (e.key) {

      case 'Enter':
        const text = input.value;

        if (text && text !== '') {
          newBlock(text);
          input.value = '';
        }
        break;

      case 'Escape':
        input.blur();
        break;

    }
  }

  function deleteBlock(id) {
    firestore.collection('blocks').doc(id).delete()
    .then((doc)=> {
      console.log('deleted');
    })
    .catch((error) => {
      console.error(error);
    });
  }

  function onKeyDown(e, id) {
    // console.log(e.key, id);

    switch (e.key) {

      case 'ArrowDown':
        moveFocus(1);
        break;

      case 'ArrowUp':
        moveFocus(-1);
        break;

      case 'Backspace':
        if (e.metaKey) {
          deleteBlock(id);
        }
        break;

    }
  }

  function moveFocus(direction) {
    const currentIndex = blocks.findIndex(block => block.id === focusId);
    const targetIndex = currentIndex + direction;
    const newFocusedBlock = blocks[targetIndex];

    if (newFocusedBlock) {
      dispatch( setFocusId(newFocusedBlock.id) );
    }
  }

  const listItems = blocks && blocks.map(block => {
    const { id, index, dateIndex } = block;
    const isSelected = selection.includes(id);
    const indexToUse = isDateList ? dateIndex : index;

    return (
      <Block
        {...block}
        index={indexToUse}
        isFocused={focusId === id}
        isSelected={isSelected}
        onMouseDown={onBlockDown}
        onClick={onBlockClick}
        onDoubleClick={onBlockDoubleClick}
        onKeyDown={onKeyDown}
        key={`block-${id}`}
      />
    );
  });

  const dropData = {
    [isDateList ? 'showDate' : 'list']: id,
  };

  return (
    <div className={styles.container}>

      <div className={styles.newTaskInputWrapper}>
        <input
          className={styles.newTaskInput}
          placeholder="Add Task"
          onKeyDown={onNewTaskKey}
          ref={newTaskInput}
        />
        <Icon
          className={styles.newTaskIcon}
          id="add"
        />
      </div>

      <div
        ref={blockItems}
        className={cx('blockList', styles.blocks)}
        data-dropdata={dropData && JSON.stringify(dropData)}
        onMouseDown={onMouseDown}
      >
        { listItems }
      </div>

    </div>
  );

}

export default BlockList;
