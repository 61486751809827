import React from 'react';
import { useSelector } from 'react-redux';
import { useFirebase, useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import {
  Route,
  Switch,
} from 'react-router-dom';

import Navbar from './Navbar/Navbar';
import Content from './Content/Content';
import Card from './Card/Card';
import Themer from './Theme/Themer';

import styles from './App.module.scss';

function App(props) {

  const firebase = useFirebase();
  const firestore = useFirestore();

  const auth = useSelector(state => state.firebase.auth);
  const selection = useSelector(state => state.app.selection);

  useFirestoreConnect([
    {
      collection: 'lists',
      where: ['owner', '==', auth.uid],
    }
  ]);

  function getProjects() {
    const projects = firestore.get('projects')
    .then((a) => {
      console.log('then', a)
    });
  }

  return (
    <div className={styles.container}>

      <Switch>

        <Route
          path="/calendar/:section?/:date?/:card?"
          exact={true}
          render={ ({ match }) => {
            const section = match && match.params && match.params.section || 'day';
            const date = match && match.params && match.params.date;
            const card = match && match.params && match.params.card;

            return (
              <div className={styles.main}>
                <Navbar currentSection={`calendar/${section}`} />

                <div className={styles.contentWrapper}>
                  <Content
                    section={section}
                    date={date}
                  />

                  {
                    card && (
                      <Card
                        id={card}
                      />
                    )
                  }
                </div>
              </div>
            );
          }}
        />

        <Route
          path="/:section?/:card?"
          exact={true}
          render={ ({ match }) => {
            const section = match && match.params && match.params.section || 'inbox';
            const card = match && match.params && match.params.card;

            return (
              <div className={styles.main}>
                <Navbar currentSection={section} />

                <div className={styles.contentWrapper}>
                  <Content section={section} />

                  {
                    card && (
                      <Card
                        id={card}
                        section={section}
                      />
                    )
                  }
                </div>
              </div>
            );
          }}
        />

      </Switch>

      <Themer
        darkMode={false}
        highlightColor={'purple'}
      />

    </div>
  );

}

export default App;
