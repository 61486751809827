export const toggleLeftPanel = (on) => ({
  type: 'TOGGLE_LEFT_PANEL',
  on,
});

export const selectBlock = (id, push) => ({
  type: 'SELECT_BLOCK',
  id,
  push,
});

export const setInputFocus = (on) => ({
  type: 'SET_INPUT_FOCUS',
  on,
});

export const setFocusId = (id) => ({
  type: 'SET_FOCUS_ID',
  id,
});
