export const DAY_NAMES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const MONTH_ABBREVIATIONS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const getDayName = (date, size) => {
  var name = date.getDay();

  if (size) {
    name = name.substr(0, size);
  }

  return DAY_NAMES[name];
};

export const getMonthName = (month) => {
  return MONTH_NAMES[month];
};

export const getMonthAbbreviation = (month) => {
  return MONTH_ABBREVIATIONS[month];
};

export const isSameDate = (a, b) => {
  return a.getDate() === b.getDate() &&
    a.getMonth() === b.getMonth() &&
    a.getFullYear() === b.getFullYear();
}

export function getDateString(date) {
  const month = MONTH_ABBREVIATIONS[ date.getMonth() ];
  return `${month} ${date.getDate()}, ${date.getFullYear()}`;
}

export function getDateReferenceString(date) {
  const yyyy = `${date.getFullYear()}`;
  const m = `${date.getMonth() + 1}`;
  const mm = m.length === 1 ? `0${m}` : m;
  const d = `${date.getDate()}`;
  const dd = d.length === 1 ? `0${d}` : d;
  return yyyy + mm + dd;
}

export function getDateFromReferenceString(string) {
  if (!string || typeof string !== 'string' || string.length !== 8) {
    return null;
  }

  return string.slice(0, 4) + '/' + string.slice(4, 6) + '/' + string.slice(6, 8);
}

export function getSpecialName(date) {
  const now = new Date();

  if (isSameDate(date, now)) {
    return 'Today';
  }

  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);
  if (isSameDate(date, yesterday)) {
    return 'Yesterday';
  }

  const tomorrow = new Date(now);
  tomorrow.setDate(tomorrow.getDate() + 1);
  if (isSameDate(date, tomorrow)) {
    return 'Tomorrow';
  }

  return undefined;
}

export function getLogicalDateString(date) {
  const specialName = getSpecialName(date);
  if (specialName) {
    return specialName;
  }

  const month = MONTH_ABBREVIATIONS[ date.getMonth() ];
  let string = `${month} ${date.getDate()}`;

  const now = new Date();
  if (now.getFullYear() !== date.getFullYear()) {
    string += `, ${date.getFullYear()}`;
  }

  return string;
}
