import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import BlockList from '../Blocks/BlockList';
import Icon from '../ui/Icon';

import styles from './Content.module.scss';

function Inbox(props) {

  const auth = useSelector(state => state.firebase.auth);
  const uid = auth.uid;
  const blocks = useSelector(state => state.firestore.ordered[`blocks-inbox`]) || [];
  const id = `inbox-${uid}`;

  const sortedBlocks = blocks.slice();
  sortedBlocks.sort((a, b) => {
    return (b.index || 0) - (a.index || 0);
  });

  useFirestoreConnect([
    {
      collection: 'blocks',
      where: [
        ['owner', '==', uid],
        ['list', '==', null],
      ],
      storeAs: `blocks-inbox`
    }
  ]);

  return (
    <div className={styles.innerContainer}>

      {
        sortedBlocks ? (
          <>
            <div className={styles.title}>
              <Icon
                className={styles.icon}
                id="inbox"
                size={40}
              />
              <div className={styles.name}>Inbox</div>
            </div>
            <div className={styles.blocks}>
              <BlockList
                blocks={sortedBlocks}
                id={null}
                owner="list"
                section="inbox"
              />
            </div>
          </>
        ) : (
          <div>Loading…</div>
        )
      }

    </div>
  );

}

export default Inbox;
