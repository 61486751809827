import React from 'react';

import styles from './Block.module.scss';

function Header(props) {
  const {
    text,
  } = props;

  return (
    <div className={styles.container}>

      { text }

    </div>
  );

}

export default Header;
