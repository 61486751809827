import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useFirestore } from 'react-redux-firebase';

import ContentEditable from '../ui/ContentEditable';
import IconPicker from '../ui/IconPicker';

import styles from './NavbarItem.module.scss';

function NavbarItem(props) {
  const {
    className,
    dropData,
    icon,
    iconIsEditable,
    id,
    isEditable,
    isSelected,
    onEdit,
    title,
  } = props;

  const firestore = useFirestore();
  const [isPickingIcon, setIsPickingIcon] = useState(false);
  const [iconPickerCoords, setIconPickerCoords] = useState({x: 0, y: 0});

  function onChange(text) {
    onEdit(id, text);
  }

  function onIconClick(e) {
    if (!iconIsEditable) {
      return;
    }

    setIsPickingIcon(true);
    setIconPickerCoords({x: e.clientX, y: e.clientY});
  }

  function onCloseIconPicker(e) {
    setIsPickingIcon(false);
  }

  function onIconChange(icon) {
    firestore.collection('lists').doc(id).update({
      icon,
    })
    .then(()=> {
      console.log('new list icon');
    })
    .catch((error) => {
      console.error(error);
    });
  }

  const classes = cx(
    styles.container,
    {
      [styles.isSelected]: isSelected,
    },
    className,
  );

  return (
    <Link className={classes} to={`/${id}`} data-dropdata={dropData}>

      {
        isPickingIcon && (
          <IconPicker
            x={iconPickerCoords.x}
            y={iconPickerCoords.y}
            onClose={onCloseIconPicker}
            onChange={onIconChange}
          />
        )
      }

      <div
        className={styles.icon}
        onClick={onIconClick}
      >{ icon }</div>
      <div className={styles.title}>
        {
          isEditable ? (
            <ContentEditable
              onChange={onChange}
              value={title}
            />
          ) : (
            <span>{ title }</span>
          )
        }
      </div>

    </Link>
  );

}

export default NavbarItem;
