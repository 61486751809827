import React, { useCallback } from 'react';

import Icon from './Icon';
import Button from './Button';
import PopupMenu from './PopupMenu';

import styles from './IconPicker.module.scss';

const ICONS = [
  {
    id: 'tadaSmall',
    title: 'Tada',
  },
  {
    id: 'clover',
    title: 'Clover',
  },
  {
    id: 'studio',
    title: 'Studio',
  },
];

function IconPicker(props) {
  const {
    x,
    y,
    onClose,
    onChange,
  } = props;

  function onIcon(e) {
    const target = e.target.closest('[data-icon-id]');
    const icon = target.getAttribute('data-icon-id');

    if (onChange) {
      onChange(icon);
    }
  }

  const icons = ICONS.map(icon => {
    return (
      <div
        className={styles.icon}
        data-icon-id={icon.id}
        key={`icon-${icon.id}`}
        onClick={onIcon}
      >
        <Button
          icon={icon.id}
        />
      </div>
    );
  });

  return (
    <PopupMenu
      className={styles.container}
      onClose={onClose}
      x={x}
      y={y}
    >
      { icons }
    </PopupMenu>
  );

}

export default IconPicker;
