import React, { useRef, useState } from 'react';
import cx from 'classnames';

import Button from './Button';
import Popup from './Popup';

import { getDateReferenceString, getMonthName, isSameDate } from '../../utils';

import styles from './DatePicker.module.scss';

function Day() {
  return (
    <div />
  );
}

function Week() {
  return (
    <div />
  );
}

function Month({ focusedDate, now, onDay, value }) {

  function onDayClick(e) {
    const target = e.target.closest(`.${styles.day}`);
    const dateString = target.getAttribute('data-datestring');

    if (onDay) {
      onDay(dateString);
    }
  }

  const weeks = [];

  let week;

  const thisMonth = focusedDate.getMonth();
  const firstOfMonth = new Date(focusedDate.getFullYear(), thisMonth, 1);
  const firstOfMonthDay = firstOfMonth.getDay();
  const lastOfMonth = new Date(focusedDate.getFullYear(), thisMonth + 1, 0);
  const lastOfMonthDay = lastOfMonth.getDay();

  function addDay(d, isNeighboringMonth) {
    const year = d.getFullYear();
    const month = d.getMonth();
    const date = d.getDate();
    const day = d.getDay();
    const dateString = getDateReferenceString(d);
    const isToday = isSameDate(d, now);
    const isCurrent = value && isSameDate(d, value);

    if (!week) {
      week = [];
    }

    week.push((
      <div
        className={cx(
          styles.day,
          {
            [styles.isNeighbor]: isNeighboringMonth,
            [styles.isToday]: isToday,
            [styles.isCurrent]: isCurrent,
          })}
        data-day={day}
        data-year={year}
        data-month={month}
        data-date={date}
        data-datestring={dateString}
        onMouseDown={onDayClick}
        key={`day-${d.getTime()}`}
      ><span className={styles.dayText}>{date}</span></div>
    ));

    if (day === 6) {
      weeks.push((
        <div
          className={cx(styles.weekRow, styles.week)}
          key={`week-${weeks.length}`}
        >{week}</div>
      ));
      week = undefined;
    }
  }

  // add days from end of previous month
  for (let i = 0; i < firstOfMonthDay; i++) {
    const date = new Date(firstOfMonth);
    date.setDate(date.getDate() - (firstOfMonthDay - i));
    addDay(date, true);
  }

  // add days from this month
  const totalDays = lastOfMonth.getDate();
  for (let i = 1; i <= totalDays; i++) {
    const date = new Date(focusedDate.getFullYear(), thisMonth, i);
    addDay(date);
  }

  // add days from beginning of next month
  const daysToAdd = 6 - lastOfMonthDay;
  for (let i = 1; i <= daysToAdd; i++) {
    const date = new Date(lastOfMonth);
    date.setDate(date.getDate() + i);
    addDay(date, true);
  }

  // return weeks;

  return (
    <div className={styles.monthContainer}>
      { weeks }
    </div>
  );
}

function DatePicker({ className, hasShortcuts, value, onChange, onClose, x, y }) {

  const [now, setNow] = useState(new Date());
  const [focusedDate, setFocusedDate] = useState(value || now);
  const month = focusedDate.getMonth();
  const monthName = getMonthName(month);
  const year = focusedDate.getFullYear();

  function onToday() {
    onChange( getDateReferenceString( new Date() ) );
  }

  function onTomorrow() {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    onChange( getDateReferenceString(date) );
  }

  function onNoDate() {
    onChange(null);
  }

  function onPrev() {
    const newDate = new Date(focusedDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setFocusedDate(newDate);
  }

  function onNext() {
    const newDate = new Date(focusedDate);
    newDate.setMonth(newDate.getMonth() + 1);
    setFocusedDate(newDate);
  }

  const classes = cx(styles.container, className);

  return (
    <Popup className={classes} onClose={onClose} x={x} y={y}>

      <div className={styles.header}>

        <div className={styles.monthName}>
          { monthName } { year }
        </div>

        <div className={styles.controls}>
          <Button
            className={styles.monthArrow}
            size={20}
            icon="arrowLeftSmall"
            onClick={onPrev}
          />
          <Button
            className={styles.monthArrow}
            size={20}
            icon="arrowRightSmall"
            onClick={onNext}
          />
        </div>

      </div>

      <Month
        focusedDate={focusedDate}
        now={now}
        onDay={onChange}
        value={value}
      />

      <div className={styles.quickOptions}>
        <Button text="Today" onClick={onToday} />
        <Button text="Tomorrow" onClick={onTomorrow} />
        <Button text="No Date" onClick={onNoDate} />
      </div>

    </Popup>
  );

}

export default DatePicker;

// import * as React from 'react';
// import PopupMenu from 'components/menu/PopupMenu';
// import { getMonthName, isSameDate } from './../../utils/utils';

// import iconStyles from './../../styles/Icons.module.scss';
// import styles from './DatePicker.module.scss';

// const cx = require('classnames');

// const TIMER_DURATION = 60000;

// export default class DatePicker extends React.Component {

//   constructor(props) {
//     super(props);

//     this.state = {
//       showingDate: props.selectedDate || props.now,
//     };
//   }

//   componentDidMount() {
//     const self = this;

//     this.timer = setInterval(function() {
//       self.setState({
//         now: new Date()
//       })
//     }, TIMER_DURATION);
//   }

//   componentWillUnmount() {
//     clearInterval(this.timer);
//   }

//   onMonthChange = (e) => {
//     const {
//       showingDate,
//     } = this.state;

//     const target = e.target.closest(`.${styles.arrow}`);
//     const direction = target.getAttribute('data-direction');

//     const newShowingDate = new Date(showingDate);
//     newShowingDate.setMonth(showingDate.getMonth() + (direction === 'next' ? 1 : -1));

//     this.setState({
//       showingDate: newShowingDate
//     });
//   }

//   onDayClick = (e) => {
//     const {
//       onChange
//     } = this.props;
//     const target = e.target.closest(`.${styles.day}`);

//     const year = target.getAttribute('data-year');
//     const month = target.getAttribute('data-month');
//     const date = target.getAttribute('data-date');

//     const newDate = new Date(year, month, date);

//     if (onChange) {
//       onChange(newDate);
//     }
//   }

//   renderWeeks = () => {
//     const {
//       now,
//       selectedDate,
//     } = this.props;
//     const {
//       showingDate,
//     } = this.state;

//     const self = this;
//     const weeks = [];

//     let week;

//     // TODO: adjust week based on user pref
//     const thisMonth = showingDate.getMonth();
//     const firstOfMonth = new Date(showingDate.getFullYear(), thisMonth, 1);
//     const firstOfMonthDay = firstOfMonth.getDay();
//     const lastOfMonth = new Date(showingDate.getFullYear(), thisMonth + 1, 0);
//     const lastOfMonthDay = lastOfMonth.getDay();

//     function addDay(d, isNeighboringMonth) {
//       const year = d.getFullYear();
//       const month = d.getMonth();
//       const date = d.getDate();
//       const day = d.getDay();
//       const isToday = isSameDate(d, now);
//       const isCurrent = selectedDate && isSameDate(d, selectedDate);

//       if (!week) {
//         week = [];
//       }

//       week.push((
//         <div
//           className={cx(
//             styles.day,
//             {
//               [styles.isNeighbor]: isNeighboringMonth,
//               [styles.isToday]: isToday,
//               [styles.isCurrent]: isCurrent,
//             })}
//           data-day={day}
//           data-year={year}
//           data-month={month}
//           data-date={date}
//           onMouseDown={self.onDayClick}
//           key={`day-${d.getTime()}`}
//         ><span className={styles.dayText}>{date}</span></div>
//       ));

//       if (day === 6) {
//         weeks.push((
//           <div
//             className={cx(styles.weekRow, styles.week)}
//             key={`week-${weeks.length}`}
//           >{week}</div>
//         ));
//         week = undefined;
//       }
//     }

//     // add days from end of previous month
//     for (let i = 0; i < firstOfMonthDay; i++) {
//       const date = new Date(firstOfMonth);
//       date.setDate(date.getDate() - (firstOfMonthDay - i));
//       addDay(date, true);
//     }

//     // add days from this month
//     const totalDays = lastOfMonth.getDate();
//     for (let i = 1; i <= totalDays; i++) {
//       const date = new Date(showingDate.getFullYear(), thisMonth, i);
//       addDay(date);
//     }

//     // add days from beginning of next month
//     const daysToAdd = 6 - lastOfMonthDay;
//     for (let i = 1; i <= daysToAdd; i++) {
//       const date = new Date(lastOfMonth);
//       date.setDate(date.getDate() + i);
//       addDay(date, true);
//     }

//     return weeks;
//   }

//   onShortcut = (e) => {
//     const {
//       onChange,
//     } = this.props;

//     const target = e.target.closest(`.${styles.shortcut}`);
//     const when = target.getAttribute('data-when');
//     let date;

//     switch (when) {
//       case 'today':
//         date = new Date();
//         break;
//       case 'tomorrow':
//         date = new Date();
//         date.setDate(date.getDate() + 1);
//         break;
//       case 'none':
//       default:
//         date = undefined;
//         break;
//     }

//     if (onChange) {
//       onChange(date);
//     }
//   }

//   render() {
//     const {
//       className,
//       hasShortcuts,
//       now,
//       x,
//       y,
//     } = this.props;
//     const {
//       showingDate,
//     } = this.state;

//     const monthName = getMonthName(showingDate.getMonth());
//     const year = showingDate.getFullYear();
//     const weeks = this.renderWeeks();

//     const classes = cx(
//       styles.container,
//       className,
//     );

//     return (
//       <PopupMenu
//         x={x}
//         y={y}
//       >

//         <div className={classes}>

//           <div className={styles.calendar}>

//             <div className={styles.month}>

//               <div
//                 className={cx(styles.monthItem, styles.monthName)}>
//                 {monthName} {year}
//               </div>

//               <div
//                 className={cx(styles.monthItem, styles.arrow)}
//                 data-direction="prev"
//                 onClick={this.onMonthChange}
//               >
//                 <svg className={cx(iconStyles.size24, iconStyles.fillBlack, styles.arrowIcon)} viewBox="0 0 24 24">
//                   <polygon points="10.55 11.5 14.5 7.55 13.5 6.55 8.55 11.5 13.5 16.45 14.5 15.45 10.55 11.5" />
//                 </svg>
//               </div>
//               <div
//                 className={cx(styles.monthItem, styles.arrow)}
//                 data-direction="next"
//                 onClick={this.onMonthChange}
//               >
//                 <svg className={cx(iconStyles.size24, iconStyles.fillBlack, styles.arrowIcon)} viewBox="0 0 24 24">
//                   <polygon points="13.5 11.5 9.55 7.55 10.55 6.55 15.5 11.5 10.55 16.45 9.55 15.45 13.5 11.5" />
//                 </svg>
//               </div>

//             </div>

//             <div className={styles.days}>
//               <div className={cx(styles.weekRow, styles.dayNames)}>
//                 <div className={styles.dayName}>S</div>
//                 <div className={styles.dayName}>M</div>
//                 <div className={styles.dayName}>T</div>
//                 <div className={styles.dayName}>W</div>
//                 <div className={styles.dayName}>T</div>
//                 <div className={styles.dayName}>F</div>
//                 <div className={styles.dayName}>S</div>
//               </div>

//               { weeks }

//             </div>

//           </div>

//           {
//             hasShortcuts ?
//             <div className={styles.shortcuts}>
//               <div className={styles.shortcut} onClick={this.onShortcut} data-when={'today'}>
//                 <svg className={cx(iconStyles.icon, iconStyles.icon16, iconStyles.fillBlue, styles.shortcutIcon, styles.todayIcon)} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//                   <path d="M5.41797 4.16602C5.83218 4.16602 6.16797 3.83023 6.16797 3.41602C6.16797 3.0018 5.83218 2.66602 5.41797 2.66602C5.00376 2.66602 4.66797 3.0018 4.66797 3.41602C4.66797 3.83023 5.00376 4.16602 5.41797 4.16602Z" />
//                   <path d="M11.5 3.41602C11.5 3.83023 11.1642 4.16602 10.75 4.16602C10.3358 4.16602 10 3.83023 10 3.41602C10 3.0018 10.3358 2.66602 10.75 2.66602C11.1642 2.66602 11.5 3.0018 11.5 3.41602Z" />
//                   <path fillRule="evenodd" clipRule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13V3ZM3 2H13C13.5523 2 14 2.44772 14 3V5H2V3C2 2.44772 2.44772 2 3 2ZM2 6V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V6H2Z" />
//                   <text className={styles.calendarText} x="8" y="13" textAnchor="middle">{now.getDate()}</text>
//                 </svg>
//                 <div className={styles.dateLabel}>Today</div>
//               </div>
//               <div className={styles.shortcut} onClick={this.onShortcut} data-when={'tomorrow'}>
//                 <svg className={cx(iconStyles.icon, iconStyles.icon16, iconStyles.fillPink, styles.shortcutIcon, styles.tomorrowIcon)} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//                   <path d="M6.16797 3.41602C6.16797 3.83023 5.83218 4.16602 5.41797 4.16602C5.00376 4.16602 4.66797 3.83023 4.66797 3.41602C4.66797 3.0018 5.00376 2.66602 5.41797 2.66602C5.83218 2.66602 6.16797 3.0018 6.16797 3.41602Z" />
//                   <path d="M10.75 4.16602C11.1642 4.16602 11.5 3.83023 11.5 3.41602C11.5 3.0018 11.1642 2.66602 10.75 2.66602C10.3358 2.66602 10 3.0018 10 3.41602C10 3.83023 10.3358 4.16602 10.75 4.16602Z" />
//                   <path d="M10.4143 10.0001L7.20718 6.79297L5.79297 8.20718L7.58586 10.0001L5.79297 11.793L7.20718 13.2072L10.4143 10.0001Z" />
//                   <path fillRule="evenodd" clipRule="evenodd" d="M3 0C1.34315 0 0 1.34315 0 3V13C0 14.6569 1.34315 16 3 16H13C14.6569 16 16 14.6569 16 13V3C16 1.34315 14.6569 0 13 0H3ZM13 2H3C2.44772 2 2 2.44772 2 3V5H14V3C14 2.44772 13.5523 2 13 2ZM2 13V6H14V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13Z" />
//                 </svg>
//                 <div className={styles.dateLabel}>Tomorrow</div>
//               </div>
//               <div className={styles.shortcut} onClick={this.onShortcut} data-when={'none'}>
//                 <svg className={cx(iconStyles.icon, iconStyles.icon16, iconStyles.fillGrey, styles.shortcutIcon)} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//                   <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14 8C14 11.3137 11.3137 14 8 14C6.70421 14 5.50436 13.5892 4.52355 12.8908L12.8908 4.52355C13.5892 5.50436 14 6.70421 14 8ZM3.10931 11.4766L11.4766 3.10931C10.4958 2.41082 9.29587 2 8 2C4.68629 2 2 4.68629 2 8C2 9.29587 2.41082 10.4958 3.10931 11.4766Z" />
//                 </svg>
//               </div>
//             </div> : ''
//           }

//         </div>

//       </PopupMenu>
//     );
//   }

// }
