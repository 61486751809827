import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import {
  getDateString,
  getDateReferenceString,
  getDateFromReferenceString,
  getDayName,
  isSameDate,
} from '../../utils';

import BlockList from '../Blocks/BlockList';
import Button from '../ui/Button';
import Icon from '../ui/Icon';

import styles from './Content.module.scss';

function Day({ date }) {

  const history = useHistory();

  // @TODO: custom hook for 'now'
  const now = new Date();
  const specifiedDate = date && new Date( getDateFromReferenceString(date) );
  const dateToShow = specifiedDate || now;
  const isToday = !specifiedDate || isSameDate(now, specifiedDate);
  const dateString = getDateReferenceString(dateToShow);

  const blocks = useSelector(state => state.firestore.ordered[`blocks-${dateString}`]) || [];

  const sortedBlocks = blocks.slice();
  sortedBlocks.sort((a, b) => {
    return (b.dateIndex || 0) - (a.dateIndex || 0);
  });

  const query = [];
  // @TODO: add uid to query
  if (isToday) {
    query.push( ['showDate', '<=', dateString] );
    query.push( ['isChecked', '==', false] );
  } else {
    query.push( ['showDate', '==', dateString] );
  }

  useFirestoreConnect([
    {
      collection: 'blocks',
      where: query,
      storeAs: `blocks-${dateString}`
    }
  ]);

  function onPrev() {
    const prevDate = new Date(dateToShow);
    prevDate.setDate(prevDate.getDate() - 1);
    history.push(`/calendar/day/${ getDateReferenceString(prevDate) }`);
  }

  function onNext() {
    const nextDate = new Date(dateToShow);
    nextDate.setDate(nextDate.getDate() + 1);
    history.push(`/calendar/day/${ getDateReferenceString(nextDate) }`);
  }

  function onToday() {
    history.push('/calendar/day');
  }

  return (
    <div className={styles.innerContainer}>

      {
        blocks ? (
          <div>

            <div className={styles.header}>

              <div className={styles.title}>
                <div className={styles.name}>{ getDateString(dateToShow) }</div>

                <div className={styles.labels}>
                  <div className={styles.dayLabel}>{ getDayName(dateToShow) }</div>
                  {
                    isToday && (
                      <div className={styles.todayLabel}>Today</div>
                    )
                  }
                </div>
              </div>

              <div className={styles.actions}>
                <Button
                  className={styles.todayButton}
                  onClick={onToday}
                  text="Today"
                  type="stroked"
                />
                <Button
                  className={styles.prevButton}
                  icon="arrowLeftLarge"
                  onClick={onPrev}
                />
                <Button
                  className={styles.nextButton}
                  icon="arrowRightLarge"
                  onClick={onNext}
                />
              </div>

            </div>

            <div className={styles.blocks}>
              <BlockList
                blocks={sortedBlocks}
                id={dateString}
                isDateList={true}
                owner="list"
                section={`calendar/day/${dateString}`}
              />
            </div>

          </div>
        ) : (
          <div>Loading…</div>
        )
      }

    </div>
  );

}

export default Day;
