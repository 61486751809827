import React from 'react';
import cx from 'classnames';

// core
import { ReactComponent as Inbox } from '../../assets/inbox.svg';
import { ReactComponent as Today } from '../../assets/today.svg';
import { ReactComponent as Week } from '../../assets/week.svg';
import { ReactComponent as Month } from '../../assets/month.svg';
import { ReactComponent as Star } from '../../assets/star.svg';
import { ReactComponent as Search } from '../../assets/search.svg';
import { ReactComponent as Add } from '../../assets/add.svg';
import { ReactComponent as Tada } from '../../assets/tada.svg';
import { ReactComponent as List } from '../../assets/list.svg';
import { ReactComponent as Trash } from '../../assets/trash.svg';
import { ReactComponent as ArrowLeftSmall } from '../../assets/arrow-left-small.svg';
import { ReactComponent as ArrowRightSmall } from '../../assets/arrow-right-small.svg';
import { ReactComponent as ArrowLeftLarge } from '../../assets/arrow-left-large.svg';
import { ReactComponent as ArrowRightLarge } from '../../assets/arrow-right-large.svg';
import { ReactComponent as XSmall } from '../../assets/x-small.svg';

// list
import { ReactComponent as TadaSmall } from '../../assets/tada-small.svg';
import { ReactComponent as Clover } from '../../assets/clover.svg';
import { ReactComponent as Studio } from '../../assets/studio.svg';

import styles from './Icon.module.scss';

const ICONS = {
  today: <Today />,
  week: <Week />,
  month: <Month />,
  star: <Star />,
  search: <Search />,
  add: <Add />,
  inbox: <Inbox />,
  tada: <Tada />,
  list: <List />,
  trash: <Trash />,
  arrowLeftSmall: <ArrowLeftSmall />,
  arrowRightSmall: <ArrowRightSmall />,
  arrowLeftLarge: <ArrowLeftLarge />,
  arrowRightLarge: <ArrowRightLarge />,
  xSmall: <XSmall />,

  tadaSmall: <TadaSmall />,
  clover: <Clover />,
  studio: <Studio />,
};

function Icon(props) {
  const {
    className,
    id,
    size = 24,
  } = props;

  const style = {
    width: size,
    height: size,
  };

  return (
    <div className={cx(styles.container, className)} style={style}>
      { ICONS[id] }
    </div>
  );

}

export default Icon;
