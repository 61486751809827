import React, { useRef, useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { setInputFocus } from '../../store/actions';

import styles from './ContentEditable.module.scss';

function ContentEditable({
  autoFocus, 
  className,
  onBlur,
  onFocus,
  onKeyDown,
  onChange,
  value
}) {

  const textInput = useRef(null);
  const [textValue] = useState(value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (autoFocus) {
      textInput.current.focus();
    }
  });

  function handleBlur() {
    dispatch( setInputFocus(false) );
    if (onBlur) {
      onBlur();
    }
  }

  function handleFocus() {
    dispatch( setInputFocus(true) );
    if (onFocus) {
      onFocus();
    }
  }

  function onKeyUp(e) {
    onChange(textInput.current.innerText);
  }

  return (
    <span
      contentEditable={true}
      className={cx(styles.container, className)}
      data-prevent-selection
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      ref={textInput}
      suppressContentEditableWarning
    >{ textValue }</span>
  );

}

export default ContentEditable;
