import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setFocusId } from '../../store/actions';
import cx from 'classnames';

import Check from './Check';
import Header from './Header';

import styles from './Block.module.scss';

const BLOCK_TYPES = {
  check: Check,
  header: Header,
};

function Block(props) {
  const {
    index,
    id,
    isSelected,
    list,
    onKeyDown,
    type,
  } = props;

  const Component = BLOCK_TYPES[type];
  const dispatch = useDispatch();

  function onMouseDown(e) {
    if (!props.onMouseDown) {
      return;
    }

    props.onMouseDown(id, e);
  }

  function onClick(e) {
    if (!props.onClick) {
      return;
    }

    props.onClick(id, e);
  }

  function onDoubleClick(e) {
    if (!props.onDoubleClick) {
      return;
    }

    props.onDoubleClick(id, e);
  }

  function onBlur() {
    dispatch( setFocusId(undefined) );
  }

  function onFocus() {
    dispatch( setFocusId(id) );
  }

  function onKey(e) {
    onKeyDown(e, id);
  }

  const classes = cx(
    'block',
    styles.container,
    {
      'isSelected': isSelected,
      [styles.isSelected]: isSelected,
    },
  );

  return (
    <div
      className={classes}
      data-id={id}
      data-index={index || 0}
      onMouseDown={onMouseDown}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >

      <Component
        {...props}
        onKeyDown={onKey}
        onFocus={onFocus}
        onBlur={onBlur}
      />

    </div>
  );

}

export default Block;
