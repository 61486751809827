import React from 'react';
import styles from './Themer.module.scss';

function Themer(props) {

  const {
    darkMode,
    highlightColor,
  } = props;

  return (
    <style className={styles.container}>
      {
        darkMode ?
        `
          :root {
            --background:   var(--black);
            --foreground:   var(--white);
            --highlight:    var(--${highlightColor || 'blue'});
            --themeOverlayOpacity: 0.25;
          }
        ` :
        `
          :root {
            --background:   var(--white);
            --foreground:   var(--black);
            --highlight:    var(--${highlightColor || 'blue'});
            --themeOverlayOpacity: 0.05;
          }
        `
      }
    </style>
  );

}

export default Themer;
