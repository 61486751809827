import React from 'react';

import Inbox from './Inbox';
import Starred from './Starred';
import List from './List';
import Day from './Day';

import styles from './Content.module.scss';

function Content({ date, section, name}) {

  let content;

  switch (section) {
    case 'day':
      content = (
        <Day
          date={date}
        />
      );
      break;

    case 'inbox':
      content = (
        <Inbox />
      );
      break;

    case 'starred':
      content = (
        <Starred />
      );
      break;

    default:
      content = (
        <List id={section} />
      );
      break;
  }

  return (
    <div className={styles.container}>

      { content }

    </div>
  );

}

export default Content;
