import React from 'react';
import cx from 'classnames';
import { useFirestore } from 'react-redux-firebase';

import Button from '../ui/Button';
import ContentEditable from '../ui/ContentEditable';

import styles from './Check.module.scss';

function Check(props) {
  const {
    id,
    index,
    isChecked,
    isStarred,
    isFocused,
    onBlur,
    onFocus,
    onKeyDown,
    text,
  } = props;

  const firestore = useFirestore();

  const classes = cx(
    styles.container,
    {
      [styles.isChecked]: isChecked,
    },
  );

  function onCheck() {
    // const {
    //   block,
    // } = this.props;
    // const {
    //   isChecked,
    // } = this.state;

    // if (this.dotTimer) {
    //   this.clearDots();
    // }

    // if (!block) {
    //   return;
    // }

    // const checked = isChecked === undefined ? !(block.data && block.data.checked) : !isChecked;

    // this.setState({
    //   isChecked: checked,
    // });

    // if (checked) {
    //   this.setState({
    //     showDots: true
    //   });
    //   this.dotTimer = setTimeout(this.clearDots, 1200);
    // }

    // this.broadcastTimer = setTimeout(this.broadcastCheck, 1200);

    firestore.collection('blocks').doc(id).update({
      isChecked: !isChecked,
    })
    .then(()=> {
      // console.log('checked');
    })
    .catch((error) => {
      console.error(error);
    });
  }

  function onTextChange(newText) {
    if (text === newText) {
      return;
    }

    firestore.collection('blocks').doc(id).update({
      text: newText,
    })
    .then(()=> {
      // console.log('new text');
    })
    .catch((error) => {
      console.error(error);
    });
  }

  function toggleStar() {
    firestore.collection('blocks').doc(id).update({
      isStarred: !isStarred,
    })
    .then(()=> {
      console.log('new text');
    })
    .catch((error) => {
      console.error(error);
    });
  }

  return (
    <div className={classes}>

      <span
        contentEditable={false}
        className={styles.checkboxWrapper}
        onClick={onCheck}
        data-prevent-selection
      >
        <svg
          className={cx(
            styles.graphic,
            {
              [styles.isChecked]: isChecked,
              // [styles.isChecking]: showDots,
            }
          )}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >

          <path
            className={styles.checkmark}
            d="M5,11 L10,16 L18.5,6.5"
          />

          <circle
            className={styles.checkbox}
            cx="12"
            cy="12"
            r="8"
          />

          { /* dots */ }

        </svg>
      </span>

      <div className={styles.content}>
        <ContentEditable
          autoFocus={isFocused}
          className={styles.text}
          onChange={onTextChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          value={text}
        />
      </div>

      <div className={styles.actions}>
        {/*<span style={{fontSize: '10px'}}>{index}</span>*/}
        <Button
          className={cx(styles.actionButton, styles.star, { [styles.isStarred]: isStarred })}
          icon="star"
          onClick={toggleStar}
          type="icon"
        />
      </div>

    </div>
  );

}

export default Check;
