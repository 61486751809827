import React from 'react'
import { render } from 'react-dom'
import { Provider, useSelector } from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore' // <- needed if using firestore
import 'firebase/storage';
// import 'firebase/functions' // <- needed if using httpsCallable
import { createStore, combineReducers, compose } from 'redux'
import { ReactReduxFirebaseProvider, firebaseReducer, isLoaded } from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' // <- needed if using firestore
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import rootReducer from './store/reducers';

import App from './components/App';

const fbConfig = {
  apiKey: "AIzaSyBjUxSO8X8aoY-Bn6SgXF-Um_yEOTv6f-8",
  authDomain: "tada-2.firebaseapp.com",
  databaseURL: "https://tada-2.firebaseio.com",
  projectId: "tada-2",
  storageBucket: "tada-2.appspot.com",
  messagingSenderId: "785852466796",
  appId: "1:785852466796:web:f8698a6c4318d148fb7a49",
  measurementId: "G-C9CFESZK03"
};

// react-redux-firebase config
const rrfConfig = {
  // userProfile: 'users',
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

// Initialize firebase instance
firebase.initializeApp(fbConfig);

// Initialize other services on firebase instance
firebase.firestore(); // <- needed if using firestore
// firebase.functions() // <- needed if using httpsCallable
// const storage = firebase.storage();
// const storageRef = storage.ref();

// Add firebase to reducers
// const rootReducer = combineReducers({
//   firebase: firebaseReducer,
//   firestore: firestoreReducer // <- needed if using firestore
// })

// Create store with reducers and initial state
const initialState = {
  firebase: {},
  firestore: {},
  ui: {
    showLeftPanel: true,
  },
  app: {
    selection: [],
  },
};
const store = createStore(rootReducer, initialState)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
};

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth);

  if (!isLoaded(auth)) {
    return (
      <div>splash screen…</div>
    );
  }

  if (!auth.uid) {
    firebase.auth().signInWithEmailAndPassword('tom+1@cloverapp.co', 'TadaTest123!')
    .then(() => {
      console.log('logged in');
    })
    .catch((error) => {
      console.error(error);
    });
    return (
      <div>logging in…</div>
    );
  }

  return children;
}

// Setup react-redux so that connect HOC can be used
function Main({ history }) {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Router history={history}>
          <AuthIsLoaded>
            <App />
          </AuthIsLoaded>
        </Router>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
}

render(<Main history={createBrowserHistory()} />, document.getElementById('root'));


// import React from 'react';
// import { render } from 'react-dom';
// import { Provider } from 'react-redux';

// import store from './store';
// import App from './components/App';

// render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById('root')
// );
