const ui = (state = { selection: [] }, action) => {

  switch (action.type) {

    case 'SELECT_BLOCK':
      let selection;

      if (action.push) {
        selection = state.selection.slice();
        selection.push(action.id);
      } else {
        selection = [action.id];
      }

      return {
        ...state,
        selection,
      };

    case 'SET_FOCUS_ID':
      return {
        ...state,
        focusId: action.id,
      };

    default:
      return state;

  }

}

export default ui;
