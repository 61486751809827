import React, { useRef, useState } from 'react';
import cx from 'classnames';

import Button from './Button';
import Icon from './Icon';
import DatePicker from './DatePicker';

import { getDateString, getLogicalDateString, getDateFromReferenceString } from '../../utils';

import styles from './DateInput.module.scss';

function DateInput({ value, placeholder, onChange }) {

  const [hasPicker, setHasPicker] = useState(false);
  const [x, setX] = useState();
  const [y, setY] = useState();

  function showPicker(e) {
    setX(e.clientX);
    setY(e.clientY);
    setHasPicker(true);
  }

  function hidePicker() {
    setX(undefined);
    setY(undefined);
    setHasPicker(false);
  }

  function onDatePick(date) {
    hidePicker();
    onChange(date);
  }

  function clearDate(e) {
    e.preventDefault();
    e.stopPropagation();
    onChange(null);
  }

  const hasValue = value && typeof value === 'string';
  const currentDate = hasValue && new Date( getDateFromReferenceString(value) );
  const valueString = hasValue ? getLogicalDateString(currentDate) : placeholder;

  return (
    <div className={styles.container}>

      <Button className={styles.input} onClick={showPicker} type={hasValue ? 'stroked' : 'dashed'}>

        <div className={styles.value}>
          { valueString }
        </div>

        {
          hasValue && (
            <Button
              className={styles.xButton}
              onClick={clearDate}
              icon="xSmall"
              size={20}
            />
          )
        }

      </Button>

      {
        hasPicker && (
          <DatePicker
            onChange={onDatePick}
            onClose={hidePicker}
            value={currentDate}
            x={x}
            y={y}
          />
        )
      }

    </div>
  );
}

export default DateInput;

/*<CalendarWidget
                date={isToday ? now : showDateAsDate}
                onDate={onShowDate}
                pickerAlignment={['top', 'right']}
                now={now}
              >
                <div className={styles.dateDisplay}>

                  {
                    isToday ?
                    <div className={styles.dateIcon}>
                      <svg className={cx(iconStyles.icon, iconStyles.icon16, iconStyles.fillBlue)} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.41797 4.16602C5.83218 4.16602 6.16797 3.83023 6.16797 3.41602C6.16797 3.0018 5.83218 2.66602 5.41797 2.66602C5.00376 2.66602 4.66797 3.0018 4.66797 3.41602C4.66797 3.83023 5.00376 4.16602 5.41797 4.16602Z" />
                        <path d="M11.5 3.41602C11.5 3.83023 11.1642 4.16602 10.75 4.16602C10.3358 4.16602 10 3.83023 10 3.41602C10 3.0018 10.3358 2.66602 10.75 2.66602C11.1642 2.66602 11.5 3.0018 11.5 3.41602Z" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13V3ZM3 2H13C13.5523 2 14 2.44772 14 3V5H2V3C2 2.44772 2.44772 2 3 2ZM2 6V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V6H2Z" />
                        <text className={styles.calendarText} x="8" y="13" textAnchor="middle">{showDateAsDate.getDate()}</text>
                      </svg>
                    </div> :
                    isTomorrow ?
                    <div className={styles.dateIcon}>
                      <svg className={cx(iconStyles.icon, iconStyles.icon16, iconStyles.fillPink)} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.16797 3.41602C6.16797 3.83023 5.83218 4.16602 5.41797 4.16602C5.00376 4.16602 4.66797 3.83023 4.66797 3.41602C4.66797 3.0018 5.00376 2.66602 5.41797 2.66602C5.83218 2.66602 6.16797 3.0018 6.16797 3.41602Z" />
                        <path d="M10.75 4.16602C11.1642 4.16602 11.5 3.83023 11.5 3.41602C11.5 3.0018 11.1642 2.66602 10.75 2.66602C10.3358 2.66602 10 3.0018 10 3.41602C10 3.83023 10.3358 4.16602 10.75 4.16602Z" />
                        <path d="M10.4143 10.0001L7.20718 6.79297L5.79297 8.20718L7.58586 10.0001L5.79297 11.793L7.20718 13.2072L10.4143 10.0001Z" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M3 0C1.34315 0 0 1.34315 0 3V13C0 14.6569 1.34315 16 3 16H13C14.6569 16 16 14.6569 16 13V3C16 1.34315 14.6569 0 13 0H3ZM13 2H3C2.44772 2 2 2.44772 2 3V5H14V3C14 2.44772 13.5523 2 13 2ZM2 13V6H14V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13Z" />
                      </svg>
                    </div> :
                    showDateAsDate ?
                    <div className={styles.dateIcon}>
                      <svg className={cx(iconStyles.icon, iconStyles.icon16, iconStyles.fillBlack)} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.41797 4.16602C5.83218 4.16602 6.16797 3.83023 6.16797 3.41602C6.16797 3.0018 5.83218 2.66602 5.41797 2.66602C5.00376 2.66602 4.66797 3.0018 4.66797 3.41602C4.66797 3.83023 5.00376 4.16602 5.41797 4.16602Z" />
                        <path d="M11.5 3.41602C11.5 3.83023 11.1642 4.16602 10.75 4.16602C10.3358 4.16602 10 3.83023 10 3.41602C10 3.0018 10.3358 2.66602 10.75 2.66602C11.1642 2.66602 11.5 3.0018 11.5 3.41602Z" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13V3ZM3 2H13C13.5523 2 14 2.44772 14 3V5H2V3C2 2.44772 2.44772 2 3 2ZM2 6V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V6H2Z" />
                        <text className={styles.calendarText} x="8" y="13" textAnchor="middle">{showDateAsDate.getDate()}</text>
                      </svg>
                    </div>:
                    ''
                  }

                  <div className={styles.dateText}>
                    {
                      isToday ?
                      'Today' :
                      isTomorrow ?
                      'Tomorrow' :
                      showDateAsDate ?
                      `${ getMonthName(showDateAsDate.getMonth()).substr(0, 3) } ${ showDateAsDate.getDate() }` :
                      'Unscheduled'
                    }
                  </div>

                  <div className={styles.dateDeleteButton} onClick={deleteShowDate}>
                    {
                      showDateAsDate ?
                      <svg className={cx(iconStyles.icon, iconStyles.icon12, iconStyles.fillBlack)} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.35355 3.35355C9.54882 3.15829 9.54882 2.84171 9.35355 2.64645C9.15829 2.45118 8.84171 2.45118 8.64645 2.64645L6 5.29289L3.35355 2.64645C3.15829 2.45118 2.84171 2.45118 2.64645 2.64645C2.45118 2.84171 2.45118 3.15829 2.64645 3.35355L5.29289 6L2.64645 8.64645C2.45118 8.84171 2.45118 9.15829 2.64645 9.35355C2.84171 9.54882 3.15829 9.54882 3.35355 9.35355L6 6.70711L8.64645 9.35355C8.84171 9.54882 9.15829 9.54882 9.35355 9.35355C9.54882 9.15829 9.54882 8.84171 9.35355 8.64645L6.70711 6L9.35355 3.35355Z" />
                      </svg> :
                      ''
                    }
                  </div>

                </div>
              </CalendarWidget>
*/
