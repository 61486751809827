import React, { useCallback } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import { toggleLeftPanel } from '../../store/actions';

import NavbarItem from './NavbarItem';
import Button from '../ui/Button';
import Icon from '../ui/Icon';

import styles from './Navbar.module.scss';

function Navbar({ currentSection }) {

  const PRIMARY_NAV_ITEMS = [
    {
      title: 'Search',
      id: 'search',
      icon: 'search',
      onClick: useCallback(() => {
        console.log('search');
      }),
    },
    {
      title: 'Inbox',
      id: 'inbox',
      icon: 'inbox',
      onClick: useCallback(() => {
        console.log('inbox');
      }),
    },
    {
      title: 'Day',
      id: 'calendar/day',
      icon: 'today',
      onClick: useCallback(() => {
        console.log('today');
      }),
    },
    {
      title: 'Week',
      id: 'calendar/week',
      icon: 'week',
      onClick: useCallback(() => {
        console.log('week');
      }),
    },
    {
      title: 'Month',
      id: 'calendar/month',
      icon: 'month',
      onClick: useCallback(() => {
        console.log('month');
      }),
    },
    {
      title: 'Starred',
      id: 'starred',
      icon: 'star',
      onClick: useCallback(() => {
        console.log('starred');
      }),
    },
  ];

  const firestore = useFirestore();
  const lists = useSelector(state => state.firestore.ordered.lists);
  const auth = useSelector(state => state.firebase.auth);
  const showPanel = useSelector(state => state.ui.showLeftPanel);
  const dispatch = useDispatch();

  const primaryItems = PRIMARY_NAV_ITEMS.map(item => {
    const { icon, id } = item;
    const isSelected = id === currentSection;
    return (
      <NavbarItem
        {...item}
        icon={(<Icon className={cx(styles.navIcon, { [styles.isSelected]: isSelected })} id={icon} />)}
        isSelected={isSelected}
        key={`nav-item-${id}`}
      />
    );
  });

  const listItems = lists && lists.map(list => {
    const { icon, id, name } = list;
    const dropData = {
      list: id,
    };
    const isSelected = id === currentSection;
    return (
      <NavbarItem
        className={'blockList'}
        dropData={dropData && JSON.stringify(dropData)}
        icon={(<Icon className={cx(styles.navIcon, { [styles.isSelected]: isSelected })} id={icon || 'list'} />)}
        iconIsEditable={true}
        id={id}
        isEditable={true}
        isSelected={isSelected}
        key={`list-item-${id}`}
        title={name}
        onEdit={onListEdit}
      />
    );
  });

  function onListEdit(id, text) {
    firestore.collection('lists').doc(id).update({
      name: text,
    })
    .then(()=> {
      console.log('new list text');
    })
    .catch((error) => {
      console.error(error);
    });
  }

  function newList() {
    const name = prompt('List Name:');

    if (!name || name === '') {
      return;
    }

    const list = {
      name,
      owner: auth.uid,
    };

    firestore.collection('lists').add(list)
    .then(()=> {
      console.log('added');
    })
    .catch((error) => {
      console.error(error);
    });
  }

  function togglePanel() {
    dispatch( toggleLeftPanel(!showPanel) );
  }

  const classes = cx(
    styles.container,
    {
      [styles.isHidden]: !showPanel
    },
  );

  return (
    <div className={classes}>

      <div className={styles.main}>

        <Button
          className={styles.toggleButton}
          onClick={togglePanel}
          icon={showPanel ? "arrowLeftSmall" : "arrowRightSmall"}
        />

        <div className={styles.primaryItems}>
          { primaryItems }
        </div>

        <div className={styles.listItems}>
          { listItems }
        </div>

      </div>

      <div className={styles.footer}>
        <Button
          className={styles.newListButton}
          icon="add"
          onClick={newList}
          size="base"
          text="New List"
        />
      </div>

    </div>
  );

}

export default Navbar;
