const ui = (state = {}, action) => {

  switch (action.type) {

    case 'TOGGLE_LEFT_PANEL':
      return {
        ...state,
        showLeftPanel: action.on,
      };

    case 'SET_INPUT_FOCUS':
      return {
        ...state,
        inputFocus: action.on,
      };

    default:
      return state;

  }

}

export default ui;
