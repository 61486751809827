import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import cx from 'classnames';

import BlockList from '../Blocks/BlockList';
import Button from '../ui/Button';
import Icon from '../ui/Icon';
import DateInput from '../ui/DateInput';

import styles from './Card.module.scss';

function Card({ id, section }) {

  const history = useHistory();
  const firestore = useFirestore();

  const block = useSelector(state => state.firestore.data[`block-${id}`]) || {};
  const mainList = useSelector(({ firestore: { data } }) => block && data.lists && data.lists[block.list]);

  const blocks = useSelector(state => state.firestore.ordered[`blocks-${id}`]) || [];

  const sortedBlocks = blocks.slice();
  sortedBlocks.sort((a, b) => {
    return (b.index || 0) - (a.index || 0);
  });

  const name = mainList && mainList.name;
  const icon = mainList && mainList.icon;

  const text = block && block.text;
  const showDate = block && block.showDate;
  const dueDate = block && block.dueDate;

  useFirestoreConnect([
    {
      collection: 'blocks',
      where: [
        ['list', '==', id],
      ],
      storeAs: `blocks-${id}`
    }
  ]);

  useFirestoreConnect([
    {
      collection: 'blocks',
      doc: id,
      storeAs: `block-${id}`
    }
  ]);

  function togglePanel() {
    const path = history.location.pathname;
    const split = path.split('/');
    split.pop();
    const newPath = split.join('/');
    history.push(newPath);
  }

  function onDelete() {
    firestore.collection('blocks').doc(id).delete()
    .then((doc) => {
      console.log('deleted');
    })
    .catch((error) => {
      console.error(error);
    });
  }

  function onShowDate(showDate) {
    firestore.collection('blocks').doc(id).update({
      showDate,
    })
    .then((doc) => {
      console.log('show date set');
    })
    .catch((error) => {
      console.error(error);
    });
  }

  function onDueDate(dueDate) {
    firestore.collection('blocks').doc(id).update({
      dueDate,
    })
    .then((doc) => {
      console.log('due date set');
    })
    .catch((error) => {
      console.error(error);
    });
  }

  return (
    <div className={styles.container}>

      <Button
        className={styles.toggleButton}
        onClick={togglePanel}
        icon="arrowRightSmall"
      />

      <div className={styles.header}>
        <div className={styles.left}>
          <Button
            text="Mark Complete"
            type="stroked"
          />
        </div>

        <div className={styles.right}>
          <Button
            className={styles.trashIcon}
            icon="trash"
            onClick={onDelete}
          />
        </div>
      </div>

      <div className={styles.body}>

        <div className={styles.list}>
          {
            icon && (
              <Icon
                className={styles.listIcon}
                id={icon}
                size={20}
              />
            )
          }
          <div className={styles.listName}>{ name }</div>
        </div>

        <div className={styles.title}>{ text }</div>

        <div className={styles.blocks}>
          <BlockList
            blocks={sortedBlocks}
            id={id}
            owner="block"
            section={id}
          />
        </div>

      </div>

      <div className={styles.footer}>

        <div className={cx(styles.dateInput, styles.showDate)}>
          <DateInput
            onChange={onShowDate}
            placeholder="Set show date"
            value={showDate}
          />
        </div>

        <div className={cx(styles.dateInput, styles.dueDate)}>
          <DateInput
            onChange={onDueDate}
            placeholder="Set due date"
            value={dueDate}
          />
        </div>

      </div>

    </div>
  );

}

export default Card;
