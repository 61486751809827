import React from 'react';
import cx from 'classnames';
import ReactDOM from 'react-dom';

import styles from './PopupMenu.module.scss';

const WINDOW_PADDING = 12;

export default class PopupMenu extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      x: 0,
      y: 0,
    };
  }

  componentDidMount() {
    this.calculatePosition();

    setTimeout(() => {
      window.addEventListener('click', this.onClick);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClick);
  }

  onClick = (e) => {
    const {
      onClose,
    } = this.props;

    const isMenu = !!e.target.closest(`.${styles.container}`);
    if (!isMenu && onClose) {
      onClose();
    }
  }

  calculatePosition = () => {
    const {
      x,
      y,
    } = this.props;

    let targetX = x;
    let targetY = y;

    if (x && y) {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const containerWidth = this.container.clientWidth;
      const containerHeight = this.container.clientHeight;

      targetX = Math.min(x, windowWidth - containerWidth - WINDOW_PADDING);
      targetY = Math.min(y, windowHeight - containerHeight - WINDOW_PADDING);

      this.setState({
        x: targetX,
        y: targetY,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.x === this.state.x && prevState.y === this.state.y) {
      return;
    }

    this.calculatePosition();
  }

  render() {
    const {
      className,
    } = this.props;
    const {
      x,
      y,
    } = this.state;

    const style = {};

    if (x && y) {
      style.left = `${x}px`;
      style.top = `${y}px`;
    }

    const el = (
      <div
        className={cx(styles.container, className)}
        ref={c => this.container = c}
        style={style}
      >
        {this.props.children}
      </div>
    );

    return ReactDOM.createPortal(
      el,
      document.body
    );
  }

}
