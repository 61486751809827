import React from 'react';
import cx from 'classnames';

import Icon from '../ui/Icon';

import styles from './Button.module.scss';

const TYPE_CLASSES = {
  'icon': styles.icon,
  'stroked': styles.stroked,
  'dashed': styles.dashed,
};

function Button(props) {
  const {
    children,
    className,
    icon,
    iconClassName,
    onClick,
    size = 'base',
    text,
    type,
  } = props;

  const iconSize = {
    'small': 20,
    'base': 20,
    'large': 20,
  }[size] || size;

  const classes = cx(
    styles.container,
    className,
    TYPE_CLASSES[type],
    {
      [styles.hasIcon]: icon !== undefined,
      [styles.hasText]: text !== undefined,
    }
  );

  let content;

  if (children) {
    content = children;
  } else {
    content = (
      <>
        {
          icon && (
            <Icon
              id={icon}
              className={cx(styles.icon, iconClassName)}
              size={iconSize}
            />
          )
        }

        <div className={styles.text}>{ text }</div>
      </>
    );
  }

  return (
    <div className={classes} data-size={size} onClick={onClick}>

      { content }

    </div>
  );

}

export default Button;
