import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import BlockList from '../Blocks/BlockList';
import Icon from '../ui/Icon';

import styles from './Content.module.scss';

function List({ id }) {

  const list = useSelector(({ firestore: { data } }) => data.lists && data.lists[id]);
  const blocks = useSelector(state => state.firestore.ordered[`blocks-${id}`]) || [];

  const sortedBlocks = blocks.slice();
  sortedBlocks.sort((a, b) => {
    return (b.index || 0) - (a.index || 0);
  });

  useFirestoreConnect([
    {
      collection: 'blocks',
      where: [
        ['list', '==', id],
      ],
      storeAs: `blocks-${id}`
    }
  ]);

  return (
    <div className={styles.innerContainer}>

      {
        (blocks && list) ? (
          <>
            <div className={styles.title}>
              {
                list.icon && (
                  <Icon
                    className={styles.icon}
                    id={list.icon}
                    size={40}
                  />
                )
              }
              <div className={styles.name}>{ list.name }</div>
            </div>
            <div className={styles.blocks}>
              <BlockList
                blocks={sortedBlocks}
                id={id}
                owner="list"
                section={id}
              />
            </div>
          </>
        ) : (
          <div>Loading…</div>
        )
      }

    </div>
  );

}

export default List;
